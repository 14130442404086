<template>
  <v-img
    :src="portraitUrl"
    :aspect-ratio="3 / 4"
    :class="$attrs['disabled'] ? 'disabled' : ''"
  />
</template>
<script>
import { LRU } from "common/utils/cache";

const PORTRAIT_CACHE = new LRU(100);

export default {
  name: "PortraitImage",
  props: { value: Object, large: { type: Boolean, default: false } },
  data() {
    return {
      portraitUrl: require("common/assets/portrait-placeholder.png"),
    };
  },
  watch: {
    async value() {
      this.fetchPortraitImage();
    },
  },
  methods: {
    async fetchPortraitImage() {
      this.portraitUrl = require("common/assets/portrait-placeholder.png");
      if (this.value && this.value.id) {
        if (!this.large) {
          let cached = PORTRAIT_CACHE.read(this.value.id);
          if (cached) {
            this.portraitUrl = cached;
          }
        }
        const url = await this.apiBlob({
          path:
            "person/portrait/" + this.value.id + (this.large ? "" : "?small"),
        });
        if (url) {
          this.portraitUrl = url;
          PORTRAIT_CACHE.write(this.value.id, url);
        }
      }
    },
  },
  async created() {
    if (this.value && this.value.id) {
      this.fetchPortraitImage();
    }
  },
};
</script>
<style scoped>
.disabled :deep(.v-image__image.v-image__image--cover) {
  opacity: 0.5 !important;
}
</style>
